<template>
  <div>
    <v-container grid-list-xs>
      <div style="position: -webkit-sticky; position: sticky">
        <v-tabs
          color="black"
          slider-color="black"
          class="mb-8 mt-2"
          center-active
          hide-slider
        >
          <v-tab
            v-for="(v, i) in tag_list"
            :key="i"
            @click="scroll(`${v.name}`)"
            class="px-2"
            ><v-card
              class="py-3 px-8 rounded-pill"
              color="leaf white--text"
              v-if="v.name == first"
            >
              {{ v.name }}
            </v-card>
            <v-card class="py-3 px-8 rounded-pill" v-else>
              {{ v.name }}
            </v-card>
          </v-tab>
        </v-tabs>
      </div>
      <v-row v-if="product.length > 1">
        <v-col
          cols="12"
          class="py-1"
          v-for="(v, i) in tag_list"
          :key="i"
          :ref="v ? v.name : ''"
        >
          <h2 class="nunito mb-6 font-weight-bold leaf--text">{{ v.name }}</h2>
          <v-lazy
            :value="product ? true : false"
            :options="{
              threshold: 0.5,
            }"
            transition="fade-transition"
            v-for="(value, index) in prod_for(v)"
            :key="index"
          >
            <v-card
              class="mt-2 mb-5"
              @click="selectProduct(value)"
              :disabled="
                value.total_left == 0 || value.out_of_stock ? true : false
              "
            >
              <v-row>
                <v-col cols="5" lg="2" class="pa-1 pl-4">
                  <v-img
                    v-if="value.media_urls.length > 0"
                    max-height="120"
                    max-width="140"
                    :src="image(value.media_urls[0])"
                  ></v-img>
                  <v-img
                    v-else
                    max-height="120"
                    max-width="140"
                    :src="emptyImage()"
                  ></v-img>
                </v-col>
                <v-col
                  cols="6"
                  lg="9"
                  :class="`flex flex-column justify-space-between ${
                    value.media_urls.length > 0 ? 'pl-2' : 'pl-5'
                  }`"
                >
                  <div>
                    <h4 class="nunito font-weight-bold">{{ value.name }}</h4>
                    <h4 class="nunito font-weight-bold blue-grey--text elipsis">
                      {{ value.description }}
                    </h4>
                    <!-- <h5 class="lato red--text">
                      {{ value.tags.length > 0 ? v.name : "" }}
                    </h5> -->
                  </div>
                  <div class="flex flex-column">
                    <h5
                      class="lato font-weight-bold font-italic red--text"
                      v-if="value.total_left == 0 || value.out_of_stock"
                    >
                      Sold Out
                    </h5>
                    <div v-else class="flex">
                      <h5
                        class="lato font-weight-bold ml-1"
                        v-if="value.price_type == 0"
                      >
                        <div v-if="value.variants.length > 1">
                          <div
                            v-for="(vars, index) in value.variants"
                            :key="index"
                          >
                            {{
                              vars.default == true
                                ? "RM" + currency(vars.price)
                                : ""
                            }}
                          </div>
                        </div>
                        <div v-else>RM {{ currency(value.unit_price) }}</div>
                      </h5>
                      <h5 class="lato font-weight-bold ml-1" v-else>
                        RM {{ currency(value.unit_price) }}
                      </h5>
                      <h5
                        class="
                          lato
                          font-weight-bold
                          ml-2
                          grey--text
                          line-through
                        "
                        v-if="value.before_discount"
                      >
                        RM {{ currency(value.before_discount) }}
                      </h5>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-lazy>
        </v-col>
      </v-row>
      <div v-else>
        <h3 class="blue-grey--text text-center my-16">No items available</h3>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "list",

  data() {
    return {
      first: null,
    };
  },

  computed: {
    ...mapGetters({
      franchise: "getFranchise",
      product: "getProduct",
      cart: "getCart",
    }),

    currency() {
      return require("currency.js");
    },

    tag_list() {
      var tags = {};
      this.product.forEach((prod) => {
        prod.tags.forEach(function (tag) {
          if (!tag.hidden_tag) {
              return (tags[tag.id] = tag); 
          }
        });
      });
      return Object.values(tags).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  },

  methods: {
    // getOut() {
    //   console.log(this.prod_for(this.tag_list));
    // },

    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },

    selectProduct(i) {
      this.$store.dispatch("updateSP", i);
      this.track(i.name);
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "product" },
      });
    },

    track(val) {
      this.$gtag.event("view_product", {
        event_category: "view_product",
        event_label: "view_product",
        value: val,
      });
    },

    scroll(refName) {
      this.first = refName;
      var element = this.$refs[refName];

      var top = element[0].offsetTop;
      window.scrollTo(0, top);
    },

    prod_for(tag) {
      var list = this.product.filter((prod) =>
        prod.tags.some((t) => t.id == tag.id && !tag.hidden_product)
      );
      var priority_list = [
        ...list.filter((p) => p.media_urls.length > 0),
        ...list.filter((p) => p.media_urls.length == 0),
      ];
      priority_list = priority_list.sort(function (a, b) {
        var names = { a: a.code || a.name, b: b.code || b.name };
        if (names.a < names.b) {
          return -1;
        }
        if (names.a > names.b) {
          return 1;
        }
        return 0;
      });
      return priority_list;
    },
  },

  mounted() {
   //  console.log("list");
    // this.scroll(this.tag_list[0].name);
    if (this.tag_list.length > 0) {
      this.first = this.tag_list[0].name;
    }
  },

  created() {},
};
</script>
<style>
.elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.line-through {
  text-decoration: line-through red;
}

.v-slide-group__prev {
  display: none !important;
  visibility: hidden;
}
</style>
